<template>
  <div class="github-profile">
    <a href="https://github.com/thaciohelmer" target="_blank">
      <p class="credits-text">Built by Thácio Helmer</p>
      <div class="github-stats-list">
        <p>GitHub Stats:</p>
        <div class="github-stats-item">
          <font-awesome-icon icon="fa-solid fa-users" class="stats-icon" />
          <p>{{ gitStats.followers }}</p>
        </div>
        <div class="github-stats-item">
          <font-awesome-icon icon="fa-solid fa-book" class="stats-icon" />
          <p>{{ gitStats.public_repos }}</p>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: "Credits",
  data() {
    return {
      gitStats: {
        public_repos: 0,
        followers: 0,
      },
    };
  },
  methods: {
    async GetGitGubStats() {
      await this.$http
        .get("https://api.github.com/users/thaciohelmer")
        .then((res) => (this.gitStats = res.data));
    },
  },
  created() {
    this.GetGitGubStats();
  },
};
</script>

<style>
.github-profile {
  margin-bottom: 2.4rem;
  display: flex;
  justify-content: center;
}

.github-profile a {
  text-decoration: none;
  color: var(--primary-ft-color);
  opacity: 0.6;
  transition: all 0.3s;
}

.github-profile a:hover {
  color: var(--secondary-ft-color);
  opacity: 1;
}

.credits-text {
  text-align: center;
}

.github-profile p {
  font-size: 1.4rem;
  font-family: var(--secondary-fm-rg);
  line-height: 1.4;
  margin-bottom: 1rem;
}

.github-stats-list {
  display: flex;
  gap: 2rem;
  justify-content: center;
}

.github-stats-item {
  display: flex;
  align-items: flex-start;
  font-size: 1.2rem;
  gap: 1.2rem;
  line-height: 1.2;
}

.stats-icon {
  font-size: 1.4rem;
}
</style>