<template>
  <VueScrollFixedNavbar :isFixed="fixed">
    <header class="header" :class="{ stick: fixed, 'nav-open': toggleNav }">
      <router-link :to="{ path: '/', hash: '#section-hero' }">
        <img src="@/assets/logo.svg" alt="Thácio Helmer" class="logo" />
      </router-link>
      <nav class="main-nav">
        <ul class="main-nav-list">
          <li
            v-for="(link, index) in nav_links"
            :key="index"
            data-aos="fade-down"
            :data-aos-duration="500 + index * 100"
            @click="ToggleNav"
          >
            <router-link :class="link.class" :to="link.to">
              {{ link.title }}
            </router-link>
          </li>
          <li>
            <a
              href="https://portifolio-files.s3.amazonaws.com/curriculo-thacio-helmer.pdf"
              class="main-nav-link nav-cta"
              target="_blank"
              >Currículo</a
            >
          </li>
        </ul>
      </nav>
      <button class="btn-mobile-nav" @click="ToggleNav">
        <font-awesome-icon
          icon="fa-solid fa-xmark"
          class="icon-mobile-nav"
          v-if="toggleNav"
        />
        <font-awesome-icon
          icon="fa-solid fa-bars"
          class="icon-mobile-nav"
          v-else
        />
      </button>
    </header>
  </VueScrollFixedNavbar>
</template>

<script>
import { VueScrollFixedNavbar } from "vue-scroll-fixed-navbar";
export default {
  name: "NavBar",
  components: { VueScrollFixedNavbar },
  data() {
    return {
      fixed: false,
      scrollPosition: 0,
      toggleNav: false,
      mobileView: false,
      nav_links: [
        {
          to: { path: "/", hash: "#section-about" },
          title: "Sobre mim",
          active: true,
          class: "main-nav-link",
        },
        {
          to: { path: "/", hash: "#section-experience" },
          title: "Experiência",
          active: true,
          class: "main-nav-link",
        },
        {
          to: { path: "/", hash: "#section-projects" },
          title: "Projetos",
          active: true,
          class: "main-nav-link",
        },
        {
          to: { path: "/", hash: "#section-contact" },
          title: "Contato",
          active: true,
          class: "main-nav-link",
        },
      ],
    };
  },
  methods: {
    handleScroll(event) {
      this.scrollPosition = window.scrollY;
      if (this.scrollPosition >= 360) {
        this.fixed = true;
      } else {
        this.fixed = false;
      }
    },
    ToggleNav() {
      this.toggleNav = !this.toggleNav;
      if (this.mobileView)
        document.querySelector("html").classList.toggle("mobile-view");
    },
    setMobileView() {
      const width = window.innerWidth;
      this.mobileView = width <= 944 ? true : false;
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.setMobileView);
    this.setMobileView();
  },
};
</script>

<style>
.logo {
  height: 5rem;
}

.header {
  align-items: center;
  display: flex;
  height: 8.4rem;
  justify-content: space-between;
  padding: 0 4.8rem;
  position: relative;
}

.main-nav-list {
  list-style: none;
  display: flex;
  gap: 3.2rem;
  align-items: center;
}

.main-nav-link span {
  color: var(--secondary-ft-color);
}

.main-nav-link:link,
.main-nav-link:visited {
  font-family: var(--primary-fm-md);
  color: var(--primary-ft-color);
  display: inline-block;
  font-weight: 500;
  font-size: 1.8rem;
  text-decoration: none;
  transition: all 0.3s;
}

.main-nav-link:hover,
.main-nav-link:active {
  color: var(--secondary-ft-color);
}

.main-nav-link.nav-cta:link,
.main-nav-link.nav-cta:visited {
  border-radius: 9px;
  background-color: var(--tertiary-bg);
  color: var(--quaternary-ft-color);
  padding: 1.2rem 2.4rem;
}

.main-nav-link.nav-cta:hover,
.main-nav-link.nav-cta:active {
  background-color: var(--primary-bg-hv);
}

.btn-mobile-nav {
  border: none;
  background: none;
  cursor: pointer;
  display: none;
}

.icon-mobile-nav {
  height: 4.8rem;
  width: 4.8rem;
  color: var(--primary-ft-color);
}

.stick {
  background-color: var(--primary-bg-op-9);
}
</style>