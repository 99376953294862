<template>
  <section
    class="section-contact"
    id="section-contact"
    data-aos="fade"
    data-aos-duration="800"
  >
    <div class="sub-title-container">
      <h3 class="sub-title">Entre em contato</h3>
    </div>
    <div class="container">
      <div class="contact-form">
        <form name="contact" @submit.prevent="handleSubmit">
          <div class="contact-request">
            <div class="input-group">
              <label for="name">Nome</label>
              <input
                v-model="name"
                type="text"
                name="name"
                placeholder="Nome Completo"
                minlength="3"
                maxlength="50"
                required
              />
            </div>
            <div class="input-group">
              <label for="email">Email</label>
              <input
                v-model="email"
                type="email"
                name="email"
                placeholder="seuemail@exemplo.com"
                maxlength="50"
                required
              />
            </div>
            <div class="input-group">
              <label for="phone">Telefone</label>
              <input
                v-model="phone"
                type="text"
                name="phone"
                v-mask="['(##) # #### - ####']"
                placeholder="(99) 9 9999 - 9999"
              />
            </div>
            <div class="input-group">
              <label for="question">Assunto</label>
              <textarea
                v-model="question"
                name="question"
                rows="4"
                minlength="10"
                maxlength="150"
                placeholder="Conte-me um pouco sobre seu projeto..."
                required
              ></textarea>
            </div>
            <div class="contact-footer">
              <div class="contact-list">
                <a href="mailto:thaciohelmer@hotmail.com" target="_blank">
                  <font-awesome-icon icon="fa-regular fa-envelope" />
                </a>
                <a
                  href="https://api.whatsapp.com/send?phone=27995036276"
                  target="_blank"
                >
                  <font-awesome-icon icon="fa-brands fa-whatsapp" />
                </a>
              </div>
              <button
                type="submit"
                class="btn"
                :class="{ 'btn--outline': validInfos }"
                :disabled="!validInfos"
              >
                Enviar
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import { mask } from "vue-the-mask";
import { mapState } from "vuex";

export default {
  name: "Contact",
  directives: { mask },
  data() {
    return {
      name: null,
      email: null,
      phone: null,
      question: null,
      validInfos: false,
    };
  },
  methods: {
    resetForm() {
      this.name = null;
      this.email = null;
      this.phone = null;
      this.question = null;
    },
    validateInfos() {
      const validName = this.name?.trim().length != 0;
      const validEmail =
        this.email?.match(/\S+@\w+\.\w{2,6}(\.\w{2})?/g)?.length > 0;
      const question = this.question?.trim().length != 0;

      if (validName && validEmail && question) this.validInfos = true;
      else this.validInfos = false;
    },
    showAlert(popupConfigs) {
      this.$store.commit("configPopup", popupConfigs);
      this.$store.commit("togglePopup", true);
    },
    handleSubmit() {
      const data = {
        name: this.name,
        email: this.email,
        phone: this.phone,
        question: this.question,
      };

      this.$http
        .post("https://api.thaciohelmer.com.br/api/form/submit", data)
        .then((res) => {
          this.resetForm();
          const content = res.data.message;
          this.showAlert({ title: "Solicitação aberta", content });
        })
        .catch((res) => {
          this.resetForm();
          const content = res.response.data.message;
          this.showAlert({ title: "Solicitação já aberta", content });
        });
    },
  },
  computed: {
    ...mapState(["showPopup", "popup"]),
  },
  watch: {
    email(newValue, oldValue) {
      this.validateInfos();
    },
    name(newValue, oldValue) {
      this.validateInfos();
    },
    question(newValue, oldValue) {
      this.validateInfos();
    },
  },
};
</script>

<style>
.section-contact {
  padding: 6.4rem 0 9.6rem 0;
}

.section-contact .sub-title-container {
  margin-top: 0px;
  flex-direction: column;
  align-items: center;
}

.contact-form {
  margin: 0 auto;
  width: 40%;
  border-radius: 0.3rem;
  overflow: hidden;
}

.contact-form .grid {
  grid-template-columns: 140px 1fr;
  column-gap: 0px;
}

.contact-request {
  padding: 2.4rem;
  background-color: var(--secondary-bg);
}

.contact-list {
  display: flex;
  align-items: flex-end;
  gap: 1.6rem;
}

.contact-list a {
  font-size: 3.2rem;
  color: var(--primary-ft-color);
  opacity: 0.6;
  transition: all 0.3s;
}

.contact-list a:hover {
  transform: translateY(-0.5rem);
  color: var(--secondary-ft-color);
  opacity: 1;
}

.input-group {
  margin-bottom: 2rem;
}

.input-group label {
  display: block;
  margin-bottom: 1rem;
  font-size: 1.8rem;
  font-family: var(--primary-font-family);
  color: var(--primary-ft-color);
  opacity: 0.8;
  line-height: 1;
}

.input-group:nth-child(1) label::after,
.input-group:nth-child(2) label::after,
.input-group:nth-child(4) label::after {
  content: "*";
  margin-left: 0.5rem;
  line-height: 1;
  color: var(--secondary-ft-color);
}

.input-group input,
.input-group textarea {
  width: 100%;
  padding: 1.2rem;
  border-radius: 0.3rem;
  border: none;
  outline: none;
  font-size: 1.4rem;
  background-color: var(--primary-ft-color);
}

.input-group input::placeholder,
.input-group textarea::placeholder {
  color: var(--quintenary-ft-color);
}

.input-group textarea {
  resize: none;
}

.input-group:nth-child(4) {
  margin-bottom: 6rem;
}

.contact-footer {
  display: flex;
  justify-content: space-between;
}
</style>