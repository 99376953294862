<template>
  <a
    v-if="project"
    :href="project.deployUrl || project.gitUrl"
    class="project-card"
    target="_blank"
  >
    <div class="project-card-content">
      <div class="card-header">
        <div class="card-links">
          <font-awesome-icon icon="fa-regular fa-folder" class="card-icon" />
          <project-links
            :gitUrl="project.gitUrl"
            :deployUrl="project.deployUrl"
            class="mt-1"
          />
        </div>
        <h3 class="project-title">{{ project.title }}</h3>
        <div class="card-body">
          <p class="card-description">{{ project.description }}</p>
        </div>
      </div>
      <div class="card-footer">
        <ul class="project-technologies">
          <li
            v-for="(tech, index) in project.technologies"
            :key="index"
            class="project-technologies-item"
          >
            {{ tech }}
          </li>
        </ul>
      </div>
    </div>
  </a>
</template>

<script>
import projectLinks from "./project-links.vue";

export default {
  name: "Project",
  props: ["project"],
  components: { projectLinks },
};
</script>

<style>
.project-card {
  text-decoration: none;
  color: var(--primary-ft-color);
}

.card-links {
  margin-bottom: 3.2rem;
}

.card-header {
  margin-bottom: 2.4rem;
}

.card-title .project-title {
  margin-bottom: 1.6rem;
}

.project-card,
.project-card .project-title {
  transition: all 0.4s;
}

.project-card:hover .project-title {
  color: var(--secondary-ft-color);
}

.project-card:hover .project-card-content {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 12px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.project-card:hover {
  transform: translateY(-2%) !important;
}

.card-icon {
  font-size: 4.2rem;
  color: var(--secondary-ft-color);
}

.card-links {
  display: flex;
  justify-content: space-between;
}

.project-card-content {
  background-color: var(--secondary-bg);
  border-radius: 0.3rem;
  padding: 3.2rem 2.4rem;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  transition: all 0.4s;
}

.card-description {
  margin-bottom: 1.4rem;
  font-size: 1.6rem;
  line-height: 1.6;
}

.card-footer .project-technologies {
  column-gap: 1.4rem;
  row-gap: 1rem;
  margin-bottom: 0px;
}

.card-footer .project-technologies-item {
  color: var(--primary-ft-color);
}

.project-card .project-link:hover .project-link-icon {
  color: var(--secondary-ft-color);
  transform: translateY(0rem);
}
</style>