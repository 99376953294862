import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    showPopup: false,
    popup: {
      title: null,
      content: null
    }
  },
  getters: {
  },
  mutations: {
    togglePopup(state, visible) {
      if (visible) {
        state.showPopup = !state.showPopup
      } else {
        state.showPopup = visible
      }
    },
    configPopup(state, { title, content }) {
      state.popup.title = title
      state.popup.content = content
    },
  },
  actions: {
  },
  modules: {
  }
})
