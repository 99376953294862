<template>
  <div class="popup" v-if="showPopup">
    <div class="popup-inner" :class="{ open: opened }" :style="PopupWidth()">
      <div class="popup-header">
        <h2 class="popup-title">
          {{ popup.title }}
        </h2>
      </div>
      <div class="popup-body">
        <p class="popup-content">
          {{ popup.content }}
        </p>
      </div>
      <div class="popup-footer">
        <button class="popup-close btn btn--full" @click="ClosePopup">
          Fechar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Popup",
  data() {
    return {
      opened: false,
    };
  },
  methods: {
    PopupWidth() {
      const windowWidth = window.innerWidth;
      const popupWidth = windowWidth < 544 ? 40 : 50;
      return `max-width: ${popupWidth}rem`;
    },
    ClosePopup() {
      this.AnimatePopup();
      setTimeout(() => {
        this.$store.commit("togglePopup", false);
      }, 200);

      clearInterval();
    },
    AnimatePopup() {
      this.opened = !this.opened;
      document.querySelector("html").classList.toggle("mobile-view");
    },
  },
  computed: mapState(["showPopup", "popup"]),
  watch: {
    showPopup(newValue, oldValue) {
      if (newValue) {
        setTimeout(this.AnimatePopup, 200);
        clearInterval();
      }
    },
  },
};
</script>

<style>
.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-inner {
  background-color: var(--secondary-bg);
  border-radius: 0.3rem;
  transition: all 0.3s;
  opacity: 0;
  transform: scale(95%);
}

.popup-inner.open {
  opacity: 1;
  transform: scale(100%);
}

.popup-header {
  margin-bottom: 2.4rem;
  padding: 2.4rem 2rem;
  border-bottom: 1px solid var(--quarternary-bg-op-2);
}

.popup-title {
  font-size: 2.4rem;
  font-family: var(--primary-fm-bk);
  color: var(--secondary-ft-color);
}

.popup-body {
  margin-bottom: 2.4rem;
}

.popup-content {
  font-size: 1.6rem;
  line-height: 1.6;
  color: var(--primary-ft-color);
  padding: 1rem 2rem;
}

.popup-footer {
  display: flex;
  justify-content: flex-end;
  padding: 1.6rem 2rem;
  border-top: 1px solid var(--quarternary-bg-op-2);
}

.popup-close.btn.btn--full {
  font-size: 1.6rem;
  padding: 1.2rem 3.2rem;
}
</style>