<template>
  <main>
    <popup />
    <heroSection />
    <about />
    <experience />
    <projects />
    <contact />
  </main>
</template>

<script>
import heroSection from "@/components/sections/hero-section.vue";
import about from "@/components/sections/about.vue";
import experience from "@/components/sections/experience.vue";
import projects from "@/components/sections/projects.vue";
import contact from "@/components/sections/contact.vue";
import popup from "@/components/layout/popup.vue";

export default {
  name: "HomeView",
  components: { heroSection, about, experience, projects, contact, popup },
};
</script>
