<template>
  <div class="social-media">
    <nav class="social-media-nav">
      <ul class="social-media-list">
        <li v-for="(link, index) in social_links" :key="index">
          <a
            class="social-media-item"
            :href="link.to"
            target="_blank"
            @click="trackEventSm(link.media)"
          >
            <font-awesome-icon
              class="social-media-icon"
              :icon="'fa-brands ' + link.icon"
            />
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import data from "@/data/data.json";

export default {
  name: "SocialMedia",
  data() {
    return {
      social_links: data.social_links,
    };
  },
  methods: {
    trackEventSm(media) {
      this.$gtag.event(media, {
        event_category: "engagement",
        event_label: "social_network",
      });
    },
  },
};
</script>

<style>
.social-media-list {
  display: flex;
  gap: 2.4rem;
}

.social-media-item:link,
.social-media-item:visited {
  font-size: 2.6rem;
  color: var(--primary-ft-color);
  transition: all 0.3s;
  display: inline-block;
}

.social-media-item:hover,
.social-media-item:hover {
  color: var(--secondary-ft-color);
  transform: translateY(-10%);
}
</style>