<template>
  <section class="section-hero" id="section-hero">
    <div class="hero">
      <p class="apresentation" data-aos="fade-up" data-aos-duration="400">
        Olá, meu nome é
      </p>
      <h1 class="name" data-aos="fade-up" data-aos-duration="600">
        Thácio Helmer.
      </h1>
      <h3 class="occupation" data-aos="fade-up" data-aos-duration="800">
        Eu desenvolvo coisas para web.
      </h3>
      <div class="resume" data-aos="fade-up" data-aos-duration="1000">
        <p>
          Eu sou desenvolvedor Web focado em criar ótimas experiências digitais.
          Atualmente desenvolvendo aplicações na
          <a href="https://www.el.com.br" target="_blank"
            >E&L Produções de Software</a
          >.
        </p>
      </div>
      <div class="container-hero" data-aos="fade-up" data-aos-duration="1200">
        <a href="#section-contact" class="btn btn--full">Entre em contato</a>
        <social-media />
      </div>
    </div>
  </section>
</template>

<script>
import socialMedia from "@/components/layout/social-media.vue";
export default {
  name: "HeroSection",
  components: { socialMedia },
};
</script>

<style>
.hero {
  max-width: 130rem;
  margin: 0 auto;
  padding: 0 3.2rem;
}

.section-hero {
  padding: 6.4rem 0 9.6rem 0;
}

.container-hero {
  display: flex;
  align-items: center;
  gap: 3.2rem;
  margin-bottom: 8rem;
}

.apresentation {
  color: var(--secondary-ft-color);
  font-size: 2.6rem;
  font-family: var(--secondary-fm-rg);
  margin-bottom: 2.4rem;
}

.name,
.occupation {
  font-size: 6.4rem;
  font-family: var(--primary-fm-bk);
  margin-bottom: 1.2rem;
}

.occupation {
  color: var(--quintenary-ft-color);
  margin-bottom: 3.6rem;
}

.resume {
  width: 60%;
  margin-bottom: 6.4rem;
}

.resume p {
  font-size: 2rem;
  line-height: 1.6;
}

.resume a {
  color: var(--secondary-ft-color);
}
</style>