vue
<template>
  <section
    class="section-about"
    id="section-about"
    data-aos="fade"
    data-aos-duration="800"
    data-aos-offset="200"
  >
    <section-title title="Sobre mim" />
    <div class="container grid grid--2-col">
      <div class="about-text-box">
        <p class="about-text">
          Meu nome é Thácio Helmer e sou apaixonado por criar soluções digitais
          incríveis. Desde 2022, quando tomei a decisão de mudar minha carreira
          de designer gráfico para a área de desenvolvimento web, meu interesse
          e dedicação só cresceram. Com cada novo estudo, descobria um novo
          mundo de possibilidades e minha paixão pelo desenvolvimento web só
          aumentava. Hoje, tenho a honra de trabalhar em uma empresa
          especializada em produção de softwares e continuo me desafiando e
          crescendo dia a dia.
        </p>
        <ul class="technologies">
          <li v-for="(tec, index) in technologies" :key="index">{{ tec }}</li>
        </ul>
      </div>
      <div class="about-img-box">
        <div class="img-effect">
          <img
            src="https://portifolio-files.s3.amazonaws.com/imgs/foto-perfil-portifolio.png"
            alt=""
            class="about-img"
          />
        </div>
      </div>
    </div>
    <div class="container all-certifications">
      <router-link tag="a" class="link" to="/certifications">
        Confira minhas certificações &rarr;
      </router-link>
    </div>
  </section>
</template>

<script>
import sectionTitle from "@/components/layout/section-title";
import data from "@/data/data.json";

export default {
  name: "About",
  components: { sectionTitle },
  data() {
    return {
      technologies: data.technologies,
    };
  },
  methods: {},
};
</script>

<style>
.section-about {
  padding: 9.6rem 0;
}

.about-text-box {
  font-size: 1.8rem;
  line-height: 1.8;
}

.about-img-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-text {
  margin-bottom: 3.6rem;
}

.about-img {
  width: 100%;
  border-radius: 0.3rem;
  max-width: 300px;
}

.img-effect {
  position: relative;
  width: 60%;
  max-width: 300px;
  z-index: 5;
}

.img-effect img {
  z-index: 4;
  position: relative;
}

.img-effect::after {
  content: "";
  top: 10%;
  right: -10%;
  height: 100%;
  width: 100%;
  max-width: 300px;
  border: 1px solid var(--tertiary-bg);
  border-radius: 0.3rem;
  position: absolute;
  z-index: 1;
  transition: all 0.3s;
}

.img-effect::before {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 5;
  border-radius: 0.3rem;
  transition: all 0.3s;
  background-color: var(--tertiary-bg-op-6);
}

.img-effect:hover::before {
  background-color: transparent;
}

.img-effect:hover:after {
  top: 5%;
  right: -5%;
}

.technologies {
  list-style: none;
  padding: 0px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 0.8rem;
  column-gap: 0.4rem;
}

.technologies li {
  padding: 0px;
  font-family: var(--secondary-fm-rg);
  font-size: 1.6rem;
}

.technologies li::before {
  content: "▶";
  font-size: inherit;
  color: var(--secondary-ft-color);
  margin-right: 1rem;
}

.all-certifications {
  text-align: center;
  font-size: 1.8rem;
}
</style>