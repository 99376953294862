<template>
  <div class="project-files">
    <a
      :href="gitUrl"
      target="_blank"
      class="project-link"
      v-if="gitUrl"
      @click="trackEventProjects()"
    >
      <font-awesome-icon class="project-link-icon" icon="fa-brands fa-github" />
    </a>
    <a :href="deployUrl" target="_blank" class="project-link" v-if="deployUrl">
      <font-awesome-icon
        class="project-link-icon"
        icon="fa-solid fa-arrow-up-right-from-square"
      />
    </a>
  </div>
</template>

<script>
export default {
  name: "ProjectLinks",
  props: ["gitUrl", "deployUrl"],
  methods: {
    trackEventProjects() {
      this.$gtag.event("projects", {
        event_category: "engagement",
        event_label: "projects",
      });
    },
  },
};
</script>

<style>
.project-files {
  display: flex;
  gap: 1.2rem;
}

.project-link:hover .project-link-icon {
  color: var(--secondary-ft-color);
  transform: translateY(-0.5rem);
}

.project-link-icon {
  font-size: 1.8rem;
  color: var(--primary-ft-color);
  transition: all 0.3s;
}
</style>