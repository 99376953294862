<template>
  <div class="container">
    <h3 class="heading-primary">{{ title }}</h3>
  </div>
</template>

<script>
export default {
  name: "SectionTitle",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style>
.heading-primary {
  font-family: var(--primary-fm-bd);
  font-size: 4.4rem;
  line-height: 1.2;
  margin-bottom: 9.6rem;
}
</style>