<template>
  <div>
    <div class="project" :class="ProjectDirection(index)" v-if="!mobileView">
      <div class="project-img">
        <a :href="project.deployUrl || project.gitUrl" target="_blank">
          <div class="img-wrapper" :style="ProjectImage(project.imgUrl)"></div>
        </a>
      </div>
      <div class="project-info" :class="ProjectDirection(index)">
        <p class="emphasis-text">Projeto destaque</p>
        <h3 class="project-title">{{ project.title }}</h3>
        <div class="project-description" :class="ProjectDirection(index)">
          <p>{{ project.description }}</p>
        </div>
        <ul class="project-technologies">
          <li
            v-for="(tech, index) in project.technologies"
            :key="index"
            class="project-technologies-item"
          >
            {{ tech }}
          </li>
        </ul>
        <project-links
          :gitUrl="project.gitUrl"
          :deployUrl="project.deployUrl"
        />
      </div>
    </div>
    <div class="project-mobile" v-else>
      <a :href="project.deployUrl || project.gitUrl" target="_blank">
        <div class="base-card-mobile" :style="ProjectImage(project.imgUrl)">
          <div class="content-card-mobile">
            <p class="emphasis-text">Projeto destaque</p>
            <h3 class="project-title">{{ project.title }}</h3>
            <div class="project-description-mobile">
              <p>{{ project.description }}</p>
            </div>
            <ul class="project-technologies">
              <li
                v-for="(tech, index) in project.technologies"
                :key="index"
                class="project-technologies-item"
              >
                {{ tech }}
              </li>
            </ul>
            <project-links
              :gitUrl="project.gitUrl"
              :deployUrl="project.deployUrl"
            />
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import projectLinks from "./project-links.vue";

export default {
  name: "EmphasisProject",
  data() {
    return {
      mobileView: false,
    };
  },
  props: ["project", "index"],
  components: { projectLinks },
  methods: {
    ProjectDirection(index) {
      return index % 2 == 0 ? "left" : "right";
    },
    ProjectImage(imageUrl) {
      return `background-image:url('${imageUrl}')`;
    },
    setMobileView() {
      const width = window.innerWidth;
      this.mobileView = width < 944 ? true : false;
    },
  },
  mounted() {},
  created() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.setMobileView);
    });
    this.setMobileView();
  },
};
</script>

<style>
.project {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12rem;
}

.project.left {
  flex-direction: row;
}

.project.right {
  flex-direction: row-reverse;
}

.project-info {
  display: flex;
  flex-direction: column;
  width: 40%;
  z-index: 10;
}

.project-info.left {
  align-items: flex-end;
  transform: translateX(-10%);
  text-align: right;
}

.project-info.right {
  align-items: flex-start;
  transform: translateX(10%);
  text-align: left;
}

.emphasis-text {
  font-family: var(--secondary-fm-rg);
  font-size: 1.4rem;
  color: var(--secondary-ft-color);
  margin-bottom: 1.2rem;
}

.project-title {
  font-family: var(--primary-fm-bd);
  font-size: 3.2rem;
  margin-bottom: 2.4rem;
}

.project-description {
  padding: 2rem;
  background-color: var(--secondary-bg);
  border-radius: 0.3rem;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 10px 20px, rgba(0, 0, 0, 0.19) 0px 6px 6px;
  transition: all 0.4s ease-in-out;
  margin-bottom: 2.4rem;
}

.project-description:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 10px 20px 20px,
    rgba(0, 0, 0, 0.3) 0px 10px 6px;
}

.project-description p {
  font-size: 1.6rem;
  line-height: 1.6;
}

.project-description.right p {
  text-align: left;
}

.project-description.left p {
  text-align: right;
}

.project-technologies {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 2.4rem;
  margin-bottom: 2.4rem;
}

.project-technologies-item {
  font-family: var(--secondary-fm-rg);
  font-size: 1.4rem;
}

.project-img {
  width: 50%;
  z-index: 9;
}

.img-wrapper {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 40rem;
  border-radius: 0.5rem;
  overflow: hidden;
  position: relative;
}

.img-wrapper::before {
  content: "";
  position: absolute;
  opacity: 0.8;
  width: 100%;
  height: 100%;
  background-color: var(--secondary-ft-color);
  transition: all 0.4s ease-in-out;
}

.img-wrapper:hover::before {
  opacity: 0;
}

.project-mobile {
  border-radius: 0.5rem;
  overflow: hidden;
  margin-bottom: 2.4rem;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 10px 20px, rgba(0, 0, 0, 0.19) 0px 6px 6px;
}

.project-description-mobile {
  margin-bottom: 3.4rem;
}

.project-description-mobile p {
  font-size: 1.8rem;
  line-height: 1.6;
}

.project-mobile a {
  color: var(--primary-ft-color);
}

.base-card-mobile {
  background-position: center;
  background-size: cover;
}

.content-card-mobile {
  background-color: var(--secondary-bg-op-9);
  padding: 2.4rem;
}
</style>