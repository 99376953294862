<template>
  <section
    id="section-experience"
    data-aos="fade"
    data-aos-duration="800"
    data-aos-offset="200"
  >
    <section-title title="Onde já trabalhei" />
    <div class="container">
      <div class="companies-grid">
        <div class="companies-names-container">
          <div class="companies-names">
            <div
              class="companie-name"
              :class="{ active: companie.active }"
              v-for="(companie, index) in companies"
              :key="index"
              @click="SetActiveCompani(companie.id)"
            >
              {{ companie.name }}
            </div>
          </div>
        </div>
        <div class="companie-info">
          <a :href="active_compani.page" class="compani-page"
            ><span>@{{ active_compani.fullName }}</span></a
          >
          <h3 class="companie-title">
            {{ active_compani.function }}
          </h3>
          <p class="period">{{ active_compani.date }}</p>
          <ul class="skill-list">
            <li v-for="(skill, index) in active_compani.skills" :key="index">
              {{ skill }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import sectionTitle from "@/components/layout/section-title";
import data from "@/data/data.json";

export default {
  name: "Experience",
  components: { sectionTitle },
  data() {
    return {
      companies: data.companies,
      active_compani: {},
    };
  },
  methods: {
    SetActiveCompani(companieId) {
      companieId = companieId || this.companies[0].id;
      let currentActiveCompanie = this.companies.find(
        (e) => e.id == companieId
      );
      this.companies
        .filter((e) => e.id != companieId)
        .forEach((e) => (e.active = false));
      currentActiveCompanie.active = true;
      this.active_compani = currentActiveCompanie;
    },
  },
  created() {
    this.SetActiveCompani();
  },
};
</script>

<style>
.companies-grid {
  display: grid;
  grid-template-columns: 20rem auto;
  column-gap: 3.2rem;
}

.companie-name {
  padding: 1.2rem 1.8rem;
  font-size: 1.4rem;
  line-height: 1.4;
  border-left: 0.5rem solid var(--quarternary-bg-op-2);
  transition: all 0.3s;
  cursor: pointer;
  font-family: var(--secondary-fm-rg);
}

.compani-page {
  font-size: 1.2rem;
  margin-bottom: 1.2rem;
  display: inline-block;
  color: var(--secondary-ft-color);
}

.companie-name.active {
  border-left: 0.5rem solid var(--tertiary-bg);
  background-color: var(--tertiary-bg-op-6);
  color: var(--tertiary-ft-color);
}

.companie-name:hover {
  background-color: var(--tertiary-bg);
  color: var(--tertiary-ft-color);
}

.companie-info {
  padding: 1.2rem;
}

.companie-title {
  font-size: 3.6rem;
  color: var(--primary-ft-color);
  margin-bottom: 1.4rem;
}

.companie-title a {
  color: var(--secondary-ft-color);
  text-decoration: none;
}

.period {
  font-size: 1.6rem;
  opacity: 0.6;
  font-family: var(--secondary-fm-rg);
  margin-bottom: 2.4rem;
}

.skill-list li {
  list-style: none;
  font-size: 1.6rem;
  line-height: 1.6;
  color: var(--primary-ft-color);
  margin-bottom: 1rem;
}

.skill-list li::before {
  content: "▶";
  font-size: 1rem;
  color: var(--secondary-ft-color);
  margin-right: 1.4rem;
}

.companies-names-container {
  overflow: scroll;
  max-height: 30rem;
  width: auto;
}

.companies-names-container::-webkit-scrollbar-thumb {
  background: transparent;
}

.companies-names-container::-webkit-scrollbar {
  width: 0;
}

.companies-names {
  width: fit-content;
}

.companie-name {
  width: 100%;
}
</style>