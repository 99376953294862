<template>
  <section
    class="section-projects"
    id="section-projects"
    data-aos="fade"
    data-aos-duration="800"
  >
    <section-title title="Alguns dos meus projetos" />
    <div class="container">
      <emphasis-project
        v-for="(project, index) in emphasisProjects"
        :key="index"
        :project="project"
        :index="index"
        data-aos="fade"
        data-aos-duration="1000"
      />

      <div class="sub-title-container">
        <h3 class="sub-title" data-aos="fade" data-aos-duration="600">
          Outros projetos
        </h3>
      </div>
      <transition-group
        name="fade"
        tag="div"
        class="card-grid"
        v-if="renderComponent"
      >
        <project
          v-for="(n, index) in pageOffSet"
          :key="index"
          :project="projects[index]"
          data-aos="fade"
          data-aos-duration="800"
        />
      </transition-group>
      <div class="button-container" v-if="totalResults > maxPerPage">
        <button class="btn btn--outline" @click="ShowMore">
          {{ buttonText }}
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import sectionTitle from "@/components/layout/section-title";
import emphasisProject from "@/components/layout/emphasis-project.vue";
import project from "@/components/layout/project.vue";

import data from "@/data/data.json";

export default {
  name: "Projects",
  components: { sectionTitle, emphasisProject, project },
  data() {
    return {
      allProjects: data.projects,
      projects: [],
      emphasisProjects: [],
      currentPage: 1,
      maxPerPage: 3,
      totalResults: 0,
      renderComponent: true,
    };
  },
  methods: {
    ShowMore() {
      if (this.currentPage < this.pageCount) {
        this.currentPage += 1;
      } else {
        this.currentPage = 1;
        this.ForceRerender();
      }
    },
    async ForceRerender() {
      this.renderComponent = false;

      await this.$nextTick();

      this.renderComponent = true;
    },
    setMobileView() {
      const width = window.innerWidth;
      this.maxPerPage = width < 704 ? 3 : 3;
    },
  },
  computed: {
    pageCount() {
      return Math.ceil(this.totalResults / this.maxPerPage);
    },
    pageOffSet() {
      return this.maxPerPage * this.currentPage;
    },
    buttonText() {
      return this.currentPage < this.pageCount
        ? "Mostrar mais"
        : "Mostrar menos";
    },
  },
  created() {
    this.projects = this.allProjects.filter((p) => !p.emphasis);
    this.emphasisProjects = this.allProjects.filter((p) => p.emphasis);
    this.totalResults = this.projects.length;
  },
};
</script>

<style>
.section-projects {
  padding: 6.4rem 0 9.6rem 0;
}

.sub-title {
  font-size: 3.2rem;
  line-height: 1.6;
  text-align: center;
  padding-bottom: 2rem;
  border-bottom: 2px solid var(--secondary-ft-color);
}

.sub-title-container {
  margin: 6rem 0;
  display: flex;
  justify-content: center;
}

.card-grid {
  width: 100%;
  margin: 0 auto;
  justify-content: center;
  display: grid;
  column-gap: 1.8rem;
  row-gap: 1.8rem;
  grid-template-columns: repeat(3, 1fr);
}

.section-projects .button-container {
  margin-top: 6.4rem;
  display: flex;
  justify-content: center;
}
</style>