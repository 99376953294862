import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import AOS from 'aos'
import VueScrollFixedNavbar from "vue-scroll-fixed-navbar";

import 'aos/dist/aos.css'
import '@/config/axios'
import '@/config/gtag'
import '@/config/fontawesome'
import "@/assets/styles/global.css";

Vue.config.productionTip = false;

Vue.use(VueScrollFixedNavbar);


new Vue({
  store,
  router,
  render: function (h) {
    return h(App);
  },
  mounted() {
    AOS.init({
      easing: "ease-out",
      anchorPlacement: "center-top"
    })
  }
}).$mount("#app");
