<template>
  <div id="app">
    <navbar />
    <router-view />
    <credits />
  </div>
</template>

<script>
import navbar from "@/components/layout/navbar";
import credits from "@/components/sections/credits.vue";

export default {
  name: "App",
  components: { navbar, credits },
};
</script>
<style>
</style>
