<template>
  <main
    class="home"
    id="section-certifications"
    data-aos="fade"
    data-aos-duration="800"
  >
    <div class="container container--link">
      <router-link :to="{ path: '/', hash: '#section-hero' }" class="link">
        &larr; Voltar
      </router-link>
    </div>
    <sectionTitle title="Certificações" />
    <section class="certifications">
      <div class="container">
        <div class="certifications-list">
          <div class="t-header">
            <div class="t-col">Conclusão</div>
            <div class="t-col">Titúlo</div>
            <div class="t-col">Feito em</div>
            <div class="t-col">Link</div>
          </div>
          <div
            v-for="(certificate, index) in certificates"
            :key="index"
            class="t-body"
          >
            <div class="t-col">{{ certificates[index].conclusion }}</div>
            <div class="t-col">{{ certificates[index].title }}</div>
            <div class="t-col">{{ certificates[index].madeAt }}</div>
            <div class="t-col">
              <a
                :href="certificates[index].link"
                target="_blank"
                class="certificate-link"
              >
                <font-awesome-icon
                  class="certificate-link-icon"
                  icon="fa-solid fa-arrow-up-right-from-square"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import data from "@/data/data.json";
import sectionTitle from "@/components/layout/section-title.vue";

export default {
  name: "Certifications",
  components: { sectionTitle },
  data() {
    return {
      certificates: data.certificates,
      currentPage: 1,
      maxPerPage: 6,
      totalResults: 0,
    };
  },
};
</script>

<style>
#section-certifications {
  padding: 6.4rem 0 9.6rem 0;
}

.t-header,
.t-body {
  display: grid;
  grid-template-columns: 150px auto 150px 100px;
  column-gap: 3.2rem;
  margin-bottom: 3.6rem;
}

.t-header .t-col {
  font-size: 1.8rem;
  font-family: var(--primary-fm-bk);
  text-align: left;
}

.t-body .t-col {
  font-size: 1.6rem;
  font-family: var(--primary-font-family-light);
  align-self: center;
}

.t-body .t-col:nth-of-type(1) {
  font-family: var(--secondary-fm-rg);
  color: var(--secondary-ft-color);
}

.t-body .t-col:nth-of-type(2) {
  font-family: var(--primary-fm-bd);
  font-size: 1.8rem;
}

.t-body .t-col:nth-of-type(4),
.t-header .t-col:nth-of-type(4) {
  text-align: center;
}

.certificate-link-icon {
  color: var(--primary-ft-color);
  transition: all 0.3s;
}

.certificate-link:hover .certificate-link-icon {
  color: var(--secondary-ft-color);
}

.container .link {
  font-size: 1.6rem;
}

.container.container--link {
  margin-bottom: 2.4rem;
}
</style>